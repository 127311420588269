<template>
    <div class="field-table flex-1 flex flex-col max-h-full max-w-full min-h-full text-base-content">
        <sub-header ></sub-header>
        <!-- <div class="flex relative flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar text-base-content"> -->
            <vue-good-table :isLoading="loading" :columns='columns' :rows='rows' :fixed-header="false"  :pagination-options="{ enabled: true, mode: 'records',dropdownAllowAll: false}">
                <template slot="table-row" slot-scope="props">
                    <div class="flex gap-3 pl-3" v-if="props.column.field === 'order_id'">
                        <router-link :to="{
                        name: 'invoice_details',
                        params: {id: props.row.order_id}
                        }" class="text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron">{{ props.row.order_number }}</router-link>
                    </div> 
                    <div v-if="props.column.field == 'tanent_name'" class="flex justify-center">
                       {{ props.row.title }}
                    </div>   
                    <div v-if="props.column.field == 'order_ref'" class="flex justify-center">
                       {{ props.row.order_reference }}
                    </div>  
                    <div v-if="props.column.field == 'order_date'" class="flex justify-center">
                       {{ FormatDateNew(new Date(props.row.created_at)) }}
                    </div> 
                    <div v-if="props.column.field == 'due_date'" class="flex justify-center">
                       {{ FormatDateNew(new Date(props.row.payment_due_date)) }}
                    </div>    
                    <div v-if="props.column.field == 'amount'" class="flex justify-center">  
                       {{ props.row.total_amount}}
                    </div>   
                    <div v-if="props.column.field == 'status'" class="flex justify-center">
                       {{ props.row.payment_status}}
                    </div> 
                </template>
                <template #loadingContent>
                    <div class="w-full py-10 justify-center flex">
                        <Loader />
                    </div>
                </template>
            </vue-good-table>            
        <!-- </div> -->
    </div>
    </template>
    
<script>
    import { getInvoiceList } from "./services"
    import SubHeader from "@/components/SubHeader"
    import Loader from "@shared/loader";
    import {FormatDateNew} from "@/plugins/functions"
    export default {
        data: function() {
            return {
                columns: [{
                    label: "Order Id",
                    field: "order_id"
                },{
                    label: "Tanent Name",
                    field: "tanent_name"
                },{
                    label: "Order Ref",
                    field: "order_ref"
                },{
                    label: "Order Date",
                    field: "order_date"
                },{
                    label: "Amount",
                    field: "amount"
                },{
                    label: "Due Date",
                    field: "due_date"
                },{
                    label: "Over Due",
                    field: "over_due"
                },{
                    label: "Status",
                    field: "status"
                },],
                loading: false,  
                rows: [],                      
            }
        },
        components: {
            SubHeader,
            Loader,
        },
        async mounted() {
            this.loading = true
            await this.setInvoiceList();
            this.loading = false
        },
        methods: {
            FormatDateNew,
            async setInvoiceList() {
                const {data} = await getInvoiceList()
                this.rows = data.data;
            },
        },
    }
    </script>
    
    <style scoped lang='scss'>
    @import "@shared/assets/mixins/mixins.scss";
    @include tableComponent;
    </style>